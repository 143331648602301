import { BrowserView, MobileView } from "react-device-detect";
import { FC, Fragment, useEffect, useState } from "react";
import ImageCell from "../components/imagecell";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {}

const image_srcs = ["/images/house/1.png", "/images/house/2.png", "/images/house/3.png", "/images/house/4.png"];

const IndexPage: FC<Props> = props => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <Fragment>
      <div className='relative w-full' data-carousel='slide'>
        <div className='relative overflow-hidden rounded-lg' style={{ height: "100vh" }}>
          {image_srcs.map((src, index) => {
            return <ImageCell key={index} src={src} active={index === activeItem} />;
          })}
        </div>
        <button
          type='button'
          className='absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none'
          data-carousel-prev
          onClick={() => {
            setActiveItem(_old => (_old - 1 + image_srcs.length) % image_srcs.length);
          }}
        >
          <span className='side-button'>
            <svg
              className='side-button-arrow'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 6 10'
            >
              <path
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M5 1 1 5l4 4'
              />
            </svg>
            <span className='sr-only'>Previous</span>
          </span>
        </button>
        <button
          type='button'
          className='absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none'
          data-carousel-next
          onClick={() => {
            setActiveItem(_old => (_old + 1) % image_srcs.length);
          }}
        >
          <span className='side-button'>
            <svg
              className='side-button-arrow'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 6 10'
            >
              <path
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='m1 9 4-4-4-4'
              />
            </svg>
            <span className='sr-only'>Next</span>
          </span>
        </button>
      </div>

      {/* Construction text */}
      <div className='const-text'>
        <a
          className='adv-link'
          onClick={() => {
            try {
              navigator.clipboard.writeText("https://uygarmuhendislik.com");
              toast.success("Link kopyalandı.");
            } catch (error) {}
          }}
          title='Tıkla ve kopyala'
        >
          uygarmuhendislik.com
        </a>{" "}
        şu anda yapım aşamasındadır.
      </div>
      <ToastContainer
      position="top-center"
      autoClose={2000}
      hideProgressBar={true}
      closeOnClick
      theme="dark"
       />
    </Fragment>
  );
};

export default IndexPage;
