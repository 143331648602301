import { FC, Fragment } from "react";

interface Props {
  src: string;
  active: boolean;
}

const ImageCell: FC<Props> = ({ src, active }) => {
  return (
    <Fragment>
      <div className={`${active ? "active" : "hidden"} duration-700 ease-in-out`} data-carousel-item>
        <img src={src} className='absolute block max-w-none w-auto h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2' alt='...' />
      </div>
    </Fragment>
  );
};

export default ImageCell;
