import { Fragment, StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import IndexPage from "./Pages";

import "./style/global.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// Redirects to root
const RedirectPage: React.FC = () => {
  window.location.replace("/");
  return <></>;
};

root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<IndexPage />} />
        <Route path='*' element={<RedirectPage />} />
      </Routes>
    </BrowserRouter>
  </StrictMode>
);
